.CTAButton {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    margin: 0.5rem auto;
    padding: none;
}

.CTAButton a {
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background: var(--cta);
    border: 2px solid var(--cta);
    border-radius: 32px;
    color: inherit;
    padding: 0.75rem 1.5rem;
    transition: all 0.25s ease-out;
}

.CTAButton a:hover {
    background: white;
    border: 2px solid white;
    color: var(--cta);
    text-decoration: none;
}