.SocialResp {
    background: var(--background);
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    margin: 0;
    max-width: 1500px;
}

.SocialResp img {
    object-fit: cover;
    width: 100%;
}

.Text {
    background: white;
    border-left: 4px solid var(--cta);
    border-right: 4px solid var(--cta);
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.5);
    color: var(--text);
    padding: 2rem;
    text-align: right;
    width: 100%;
    z-index: 5;
}

.Text h2 {
    color: var(--highlight);
}

.Text p {
    color: var(--secondary-color);
    line-height: 1.8;
}

@media (min-width: 850px) {
    .SocialResp {
        flex-direction: row;
        margin: 16rem auto 8rem;
    }

    .SocialResp img {
        border-radius: 4px;
        bottom: 4rem;
        left: 2rem;
        position: relative;
        width: 40%;
    }

    .Text {
        border-radius: 4px;
        border-right: 0;
        position: relative;
        right: 4rem;
        width: 40%;
    }
}