.CultureCode {
    background: var(--background);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2rem 0 0;
    max-width: 1500px;
}

.CultureCode img {
    object-fit: cover;
    width: 100%
}

.Text {
    background: white;
    border-left: 4px solid var(--cta);
    border-right: 4px solid var(--cta);
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.5);
    color: var(--text);
    padding: 2rem;
    width: 100%;
    z-index: 5;
}

.Text h2 {
    color: var(--highlight);
}

.Text p {
    color: var(--secondary-color);
    line-height: 1.8;
}

.Text ul {
    color: var(--secondary-color);
}

@media (min-width: 850px) {
    .CultureCode {
        flex-direction: row;
        margin: 4rem auto;
    }

    .CultureCode img {
        border-radius: 4px;
        position: relative;
        right: 2rem;
        top: 4rem;
        width: 40%;
    }

    .Text {
        border-left: 0;
        border-radius: 4px;
        left: 4rem;
        position: relative;
        width: 40%;
    }
}