.ExecutiveLeadership {
    background: white;
    border: 4px solid var(--cta);
    margin: auto;
    max-width: 1300px;
    padding: 2rem 0 1rem;
    text-align: center;
}

.ExecutiveLeadership h2 {
    color: var(--highlight);
}

.ExecutiveLeadership p {
    font-style: italic;
    margin: 0 auto 2rem;
    max-width: 700px;
    width: 90%;
}

.Leaders {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    max-width: 800px;
}