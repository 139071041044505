.Management {
    background: white;
    border: 4px solid var(--cta);
    margin: auto;
    max-width: 1300px;
    padding: 2rem 0 1rem;
}

.Management h2 {
    color: var(--highlight);
    text-align: center;
}

.Leaders {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    max-width: 1100px;
}