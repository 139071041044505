.Internship {
  text-align: center;
}

.Internship h2 {
  color: var(--highlight);
  font-size: 2.5rem;
}

.Internship h3 {
  color: var(--highlight);
  font-size: 1.5rem;
  font-weight: normal;
  margin-bottom: 0;
}

.Internship p {
  margin-bottom: 2rem;
  padding: 0 1rem;
}
