.Contact {
    margin: auto;
    text-align: center;
}

.Captcha {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 1rem auto;
}

.ContactContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 1rem;
}

@media (min-width: 850px) {
    .ContactContent {
        flex-direction: row;
        padding: 4rem 1rem;
    }
}