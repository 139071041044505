.LeaderBio {
    padding: 0 2rem;
    position: relative;
    text-align: center;
}

.LeaderBio img {
    border-radius: 50%;
    height: 8rem;
    margin-top: 1.5rem;
    object-fit: cover;
    width: 8rem;
}

.LeaderBio h1 {
    color: var(--highlight);
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}

.LeaderBio h3 {
    font-style: italic;
    font-weight: normal;
    margin-top: 0;
}

.LeaderBio p {
    line-height: 1.5;
    margin-bottom: 0;
    padding-bottom: 1.5rem;
}