.Jumbotron {
    /* background: linear-gradient(to top,rgba(0, 0, 0, 0.6) 100%, transparent), 
    url("https://firebasestorage.googleapis.com/v0/b/one-energy-global.appspot.com/o/homepageJumboStatic.png?alt=media&token=1dc5b8f0-813b-4926-84d9-9adb482e4c2c") 50%/cover no-repeat border-box; */
    background: linear-gradient(to top,rgba(0, 0, 0, 0.5) 100%, transparent), 
    url("https://cdn.pixabay.com/photo/2019/11/08/09/27/panorama-4610864_960_720.jpg") 40%/cover no-repeat border-box;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.5);
    color: white;
    margin: auto;
    overflow: hidden;
    padding: 8rem 0.5rem 5rem;
    position: relative;
    text-align: center;
}

.Jumbotron h1 {
    font-size: 2.5rem;
    margin: 1.5rem 0 0.5rem;
}

.Jumbotron p {
    font-size: var(--secondary-text);
    margin: 0 0 0.5rem;
    opacity: 0.7;
}

/* .Video,
.Video source {
    display: none;
} */

.Buttons {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2rem auto;
}

.LearnMore {
    align-self: stretch;
    background: none;
    border: none;
    margin-bottom: 2rem;
    padding: 0;
}

.LearnMore a {
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 2px solid white;
    border-radius: 32px;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.75rem 3rem;
    text-decoration: none;
    transition: all 0.25s ease-out;
}

.LearnMore a:hover {
    background: white;
    border: 2px solid white;
    color: var(--cta);
}

@media (min-width: 440px) {
    .Buttons {
        display: block;
        margin-top: 2rem;
    }

    .LearnMore {
        margin: 0 1rem 0 0;
    }
}

@media (min-width: 720px) {
    .Jumbotron h1 {
        font-size: 2.5rem;
    }
}

/* @media (min-width: 850px) {
    .Jumbotron {
        background: rgba(0, 0, 0, 0.6);
    }

    .Video {
        bottom: 0;
        display: block;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: -1; 
    }

    .Video source {
        display: initial;
    }
} */

/* @media (min-width: 1000px) {
    .Video {
        top: -10%;
    }
} */