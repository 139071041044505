.Footer {
    background: #f8f1f1;
    box-shadow: 0 -1px 4px 1px rgba(0, 0, 0, 0.2);
    padding: 3rem 1rem 1rem;
}

.Footer h3 {
    margin-top: 0;
}

.MainContent {
    margin: auto;
    max-width: 1000px;
}

@media (min-width: 500px) {
    .MainContentBottom {
        display: flex;
        justify-content: space-between;
        margin: 2rem auto;
        max-width: 500px;
    }
}

@media (min-width: 850px) {
    .Footer {
        padding: 3rem 2rem 1rem;
    }

    .MainContent {
        display: flex;
        justify-content: space-between;
    }

    .MainContentBottom {
        margin: 2rem 0;
        width: 60%;
    }
}