.RegisterForm h6 {
    color: var(--cta);
}

.Google {
    background: #158eff;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.3);
    border: none;
    border-radius: 4px;
    color: white;
    display: block;
    margin: 1rem auto 0;
    padding: 0.8rem;
    width: 100%;
}

.EmailSent {
    color: green;
    margin: 0;
}

.Error {
    color: red;
    text-align: center;
}

.Divider {
    margin: 1rem 0 0;
    text-align: center;
}

.ForgotPassword {
    bottom: 0.6rem;
    cursor: pointer;
    font-size: 0.7rem;
    margin: 0;
    position: relative;
    text-align: right;
    text-decoration: underline;
}

.ForgotPassword:hover {
    color: #666666;
    text-decoration: none;
}

.ButtonSubmit {
    margin: 1rem auto 0.5rem !important;
}

.Switch a {
    color: initial;
    cursor: pointer;
    font-size: 0.8rem;
    text-align: center;
    text-decoration: underline;
}

.Switch a:hover {
    color: #666666;
    text-decoration: none;
}

@media (min-width: 720px) {
    .RegisterForm {
        width: 45%;
    }
}