.Director {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 2rem auto;
    text-align: center;
    width: 190px;
}

.Director h3 {
    margin: 1rem 0 0;
}

.Director p {
    margin: 0.5rem 0;
}

.Divider {
    background: var(--cta);
    height: 0.5rem;
    margin: auto;
    width: 4.5rem;
}

@media (min-width: 525px) {
    .Director {
        margin: 2rem;
        text-align: initial;
    }

    .Divider {
        margin: initial;
    }
}