.Mission {
    background: var(--secondary-color);
}

.Mission h2 {
    color: white;
    font-size: var(--secondary-header);
    margin: 0 0 1rem;
}

.Mission p {
    color: white;
    line-height: 1.5;
    margin: 0.5rem auto;
    width: 90%
}

.MissionContent {
    margin: auto;
    max-width: 800px;
    padding: 2rem 0;
    text-align: center;
    width: 90%;
}

@media (min-width: 850px) {    
    .Mission h2 {
        font-size: var(--jumbo-text);
    }
    
    .Mission p {
        font-size: var(--secondary-text);
    }
    
    .MissionContent {
        padding: 4rem 0;
    }
}