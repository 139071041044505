.Links {
    list-style: none;
    margin: 0;
    padding: 0;
}

.Logo {
    display: none;
}

@media (min-width: 850px) {
    .Links {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .Logo {
        display: initial;
    }
}