.ToggleButton {
    background: transparent;
    border: 1px solid white;
    border-radius: 4px;
    cursor: pointer;
    margin: 0.25rem 0.5rem;
    padding-bottom: 0;
    padding-top: 0;
    vertical-align: middle;
    width: 3rem;
}

.ToggleButton:focus {
    outline: none;
}

.ToggleButton__bar {
    background: white;
    display: block;
    height: 0.2rem;
    margin: 0.6rem 0;
    width: 100%;
}

@media (min-width: 850px) {
    .ToggleButtonContainer {
        display: none;
    }
}