.FooterLinks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

@media(min-width: 500px) {
    .FooterLinks {
        justify-content: space-between;
    }
}