.Newsletter {
    margin: auto;
    max-width: 500px;
    text-align: center;
}

@media(min-width: 850px) {
    .Newsletter {
        margin: 2rem 0;
        text-align: left;
        width: 35%;
    }
}