.FooterBottom {
    margin-bottom: 0;
    margin-top: 2rem;
    text-align: center;
}

.FooterBottom a {
    color: var(--cta);
    text-decoration: none;
}

.FooterBottom a:hover {
    color: var(--cta-hover);
}