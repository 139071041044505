.Link {
    cursor: pointer;
    margin: 0 1.5rem;
}

.Link a {
    color: white;
    text-decoration: none;
}

.Link a:hover {
    opacity: 1;
}

@media (min-width: 850px) {
    .Link a {
        opacity: 0.7;
    }
}