.FooterContacts {
    margin: 2rem 0;
    text-align: center;
}

.FooterContacts h3 {
    margin-bottom: 1rem;
}

.FooterContacts a {
    text-decoration: none;
}

.FooterContacts a:hover {
    color: #666565;
}

.ContactUs p,
.ContactUs a {
    align-items: center;
    color: inherit;
    display: flex;
    justify-content: center;
}

.ContactUs svg {
    margin-right: 0.25rem;
}

.SocialLinks {
    display: flex;
    justify-content: center;
}

.SocialLinks p {
    margin: 0;
}

.SocialLinks a {
    color: inherit;
    margin-right: 0.5rem;
}

@media (min-width: 500px) {
    .FooterContacts {
        margin: 0;
        text-align: left;
    }

    .ContactUs p,
    .ContactUs a {
        justify-content: initial;
    }

    .SocialLinks {
        justify-content: initial;
    }
}