.Jumbotron {
    background: linear-gradient(to top,rgba(0, 0, 0, 0.5) 100%, transparent), 
    url("https://cdn.pixabay.com/photo/2015/03/09/15/55/bike-665925__340.jpg") 40%/cover no-repeat border-box;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.5);
    color: white;
    margin: auto;
    padding: 9rem 0 6rem;
    position: relative;
    text-align: center;
    width: 100%;
}

.Jumbotron h1 {
    font-size: 2rem;
    margin: 1rem auto;
    max-width: 800;
    width: 90%;
}

@media (min-width: 850px) {
    .Jumbotron h1 {
        font-size: 4rem;
    }
}