.Directors {
    background: var(--secondary-color);
    color: white;
    padding: 4rem 0;
}

.Directors h1 {
    margin-bottom: 3.5rem;
    text-align: center;
}

.DirectorsGroup {
    margin: auto;
    max-width: 1000px;
}

@media (min-width: 525px) {
    .DirectorsGroup {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}