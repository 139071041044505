.SideDrawer {
    background: var(--secondary-color);
    box-shadow: 1px 0 4px 2px rgba(0, 0, 0, 0.5);
    font-weight: bold;
    height: 100%;
    left: 0;
    max-width: 400px;
    padding-top: 2rem;
    position: fixed;
    text-align: center;
    top: 0;
    transition: transform 0.3s ease-out;
    width: 80%;
    z-index: 100;
}

.SideDrawer img {
    margin: 3rem auto;
    width: 50%;
}

.SideDrawer li {
    font-size: 1.5rem;
    margin: 2rem 0;
}

@media (min-width: 850px) {
    .SideDrawer nav {
        display: none;
    }
}