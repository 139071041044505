.MenuItem {
    cursor: pointer;
    margin-bottom: 0.5rem;
    text-align: center;
}

.MenuItem:hover {
    color: #c0c0c0;
}

@media (min-width: 475px) {
    .MenuItem {
        text-align: left;
    }
}