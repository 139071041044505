.FooterLink {
    margin: 0.5rem auto;
}

.FooterLink a {
    color: inherit;
    text-decoration: none;
}

.FooterLink a:hover {
    color: #666565;
}

@media(min-width: 500px) {
    .FooterLink {
        margin: 0;
    }
}