.AccountContainer {
    background: #e4e4e4;
    position: fixed;
    height: 100vh;
    width: 100%;
}

.Account {
    color: rgba(0, 0, 0, 0.5);
    height: 75%;
    margin: auto;
    max-width: 900px;
    padding: 2% 1rem;
    width: 90%;
}

.AccountDisplay {
    margin-top: 2rem;
}

.Display {
    width: 100%;
}

.Logout {
    background: none;
    border: none;
    bottom: 2rem;
    color: rgba(0, 0, 0, 0.5);
    left: 50%;
    padding: 0;
    position: absolute;
    text-decoration: underline;
    transform: translateX(-50%);
}

.Logout:hover {
    color: rgba(0, 0, 0, 1);
    opacity: 1;
    text-decoration: none;
}

@media(min-width: 475px) {
    .Account {
        padding: 10% 1rem;
    }
    
    .AccountDisplay {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
    
    .Display {
        padding: 0 0 1rem 3rem;
    } 
}

@media(min-width: 850px) {
    .Logout {
        display: none;
    }
}