.Leader {
    margin: 1rem;
    text-align: center;
    width: 20rem;
}

.Leader img {
    border-radius: 50%;
    cursor: pointer;
    height: 8rem;
    object-fit: cover;
    width: 8rem;
}

.Leader h3 {
    color: var(--highlight);
    cursor: pointer;
}

.Leader img:hover {
    opacity: 0.7;
}

.Leader h3:hover {
    color: var(--secondary-color);
}