.Button {
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background: var(--cta);
    border: none;
    border-radius: 32px;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    margin: 1rem 0.5rem;
    padding: 0.75rem 1.5rem;
    transition: background-color 0.25s linear;
    width: 10rem;
}

.Button:hover {
    background: var(--cta-hover);
}

.Button a {
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background: var(--cta);
    border-radius: 32px;
    color: inherit;
    display: block;
    padding: 0.75rem 1.5rem;
    text-decoration: none;
    transition: background-color 0.25s linear;
    width: 10rem;
}

.Button a:hover {
    background: var(--cta-hover);
}