.PositionForm {
    padding: 1rem;
    position: relative;
}

.Confirm {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.Confirm img {
    animation: appear 1s ease-out;
    margin: 2rem auto 0;
    max-width: 250px;
    padding-left: 1.8rem;
    width: 100%;
}

.Confirm p {
    color: var(--highlight);
    font-size: 1.5rem;
    font-weight: bold;
}

.PositionFormContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}

.ContactInfo h2 {
    color: var(--highlight);
}

.ContactInfo a {
    align-items: center;
    color: var(--cta);
    display: flex;
    justify-content: center;
    margin: 0.5rem 0;
    position: relative;
    right: 0.2rem;
    text-decoration: none;
}

.ContactInfo a:hover {
    color: var(--cta-hover);
}

.ContactInfo svg {
    margin-right: 0.5rem;
}

.Greeting {
    font-size: 1.2rem;
    font-style: italic;
}

.Hours {
    margin: 0.5rem 0;
}

.DividerLarge {
    display: none;
}

.FileInput {
    padding: 0 12px;
}

.FileLabel {
    font-size: 0.6rem;
    padding: 0 12px;
}

@media (min-width: 700px) {
    .PositionFormContent {
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
    }

    .PositionFormContent form {
        width: 47%;
    }

    .ContactInfo {
        width: 47%;
    }

    .ContactInfo a {
        justify-content: initial;
    }

    .DividerLarge {
        display: initial;
    }
    
    .DividerSmall {
        display: none;
    }
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}