.Donate {
    padding: 1rem;
    position: relative;
    text-align: center;
}

.Donate h2 {
    color: var(--highlight);
    font-weight: bold;
}

.Donate p {
    font-size: 1.25rem;
    line-height: 1.2;
}

.Buttons {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 350px;
}

.Buttons button {
    border: none;
    border-radius: 32px;
    color: white;
    font-size: 1.25rem;
    margin: 0.5rem;
    padding: 0;
    transition: background-color 0.3s ease-out;
}

.Buttons a {
    border-radius: 32px;
    color: inherit;
    display: block;
    padding: 0.5rem 1.5rem;
    text-decoration: none;
    transition: background-color 0.3s ease-out;
}

.Buttons i {
    margin-right: 0.25rem;
}

.PayPal {
    background: #3b55ed;
}

.PayPal:hover {
    background: #5770ff;
}

.CashApp {
    background: #42c210;
}

.CashApp:hover {
    background: #4fd41a;
}

@media(min-width: 500px) {
    .Buttons {
        flex-direction: row;
        justify-content: space-between;
    }

    .Buttons a {
        width: 10rem;
    }
}