.DepartmentReverse {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    margin: 0;
    max-width: 1500px;
}

.DepartmentReverse img {
    margin: 0;
    object-fit: cover;
    width: 100%;
}

.Text {
    background: white;
    border-left: 4px solid var(--cta);
    border-right: 4px solid var(--cta);
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.3);
    margin: 0;
    padding: 1rem;
    text-align: center;
    width: 100%;
}

.Text h3 {
    color: var(--highlight);
    font-size: 1.5rem;
    margin-top: 0;
}

.Text p {
    line-height: 2rem;
}

@media (min-width: 850px) {
    .DepartmentReverse {
        flex-direction: row;
        margin: 4rem auto;
    }

    .DepartmentReverse img {
        border-radius: 4px;
        margin: 0 1rem;
        width: 40%;
    }

    .Text {
        border-left: 0;
        border-radius: 4px;
        margin: 0 1rem;
        text-align: right;
        width: 40%;
    }

    .Text h3 {
        font-size: var(--secondary-header);
    }
}