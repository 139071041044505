.Navbar {
    background: var(--secondary-color);
    box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.5);
    padding: 0.5rem 1rem;
    position: fixed;
    transform: translateY(-7.5rem);
    transition: all 0.25s ease-out;
    width: 100%;
    z-index: 10;
}

.Navbar nav {
    display: none;
}

@media (min-width: 850px) {
    .Navbar nav {
        display: initial;
    }
}