.Modal {
    -ms-overflow-style: none;
    scrollbar-width: none;
    background: #eee;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.7);
    max-height: 80%;
    max-width: 1100px;
    left: 25%;
    margin: auto;
    overflow: auto;
    padding: 1rem;
    position: fixed;
    top: 10%;
    transform: translateX(-50%);
    /* transition: opacity 0.75s ease-out, transform 0.75s ease-out; */
    transition: all 0.75s ease-out;
    width: 50%;
}

@media (max-width: 1200px) {
    .Modal {
        left: 5%;
        width: 90%;
    }
}