:root {
  --background: white;
  --background-secondary: white;
  --cta: #f58a8a;
  --cta-hover: #fcb0b0;
  --highlight: #09c8ee;
  --jumbo-text: 3.5rem;
  --secondary-color: #22a4be;
  --secondary-header: 2rem;
  --secondary-text: 1.25rem;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}